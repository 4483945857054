import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick"



const SliderPhoto = () => {
  function Img1() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/1.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img2() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/2.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img3() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/3.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img4() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/4.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img5() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/5.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img6() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/6.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img7() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/7.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
      />
  }

  function Img8() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/8.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img9() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/9.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img10() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/10.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]} />
  }

  function Img11() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/11.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]} />
  }

  function Img12() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/12.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
       />
  }

  function Img13() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/13.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
      />
  }

  function Img14() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/14.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
      />
  }

  function Img15() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/15.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
      />
  }

  function Img16() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/16.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
      />
  }

  function Img17() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/17.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
      />
  }

  function Img18() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/18.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
      />
  }

  function Img19() {
    return <StaticImage src="https://admin.shroom4you.com/wp-content/uploads/2023/10/19.jpg"
      alt=""
      placeholder="blurred"
      layout="fullWidth"
      style={{
         gridArea: "1/1",
         maxHeight: 300,
         maxWidth: 300,
       }}
      formats={["auto", "webp", "avif"]}
      />
  }

  return (
    <>
      <Slider
        slidesToShow={10}
        swipeToSlide={true}
        focusOnSelect={false}
        arrows={false}
        dots={true}
        infinite={true}
        speed={500}
        initialSlide={10}
        slidesToScroll={1}
        autoplay={true}
        responsive= {[
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 9,
          }
        },
      {
        breakpoint: 1720,
        settings: {
          slidesToShow: 8,
        }
      },
      {
        breakpoint: 1520,
        settings: {
          slidesToShow: 7,
        }
      },
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 6,
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 969,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        }
      }
    ]}
      >
        <div className="mush s-img">
          {Img1()}
        </div>
        <div className="mush s-img">
          {Img2()}
        </div>
        <div className="mush s-img">
          {Img3()}
        </div>
        <div className="mush s-img">
          {Img4()}
        </div>
        <div className="mush s-img">
          {Img5()}
        </div>
        <div className="mush s-img">
          {Img6()}
        </div>
        <div className="mush s-img">
          {Img7()}
        </div>
        <div className="mush s-img">
          {Img8()}
        </div>
        <div className="mush s-img">
          {Img9()}
        </div>
        <div className="mush s-img">
          {Img10()}
        </div>
        <div className="mush s-img">
          {Img11()}
        </div>
        <div className="mush s-img">
          {Img12()}
        </div>
        <div className="mush s-img">
          {Img13()}
        </div>
        <div className="mush s-img">
          {Img14()}
        </div>
        <div className="mush s-img">
          {Img15()}
        </div>
        <div className="mush s-img">
          {Img16()}
        </div>
        <div className="mush s-img">
          {Img17()}
        </div>
        <div className="mush s-img">
          {Img18()}
        </div>
        <div className="mush s-img">
          {Img19()}
        </div>
      </Slider>
    </>
  );
};

export default SliderPhoto;
